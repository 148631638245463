import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'qr-workflow-history-dialog',
  standalone: true,
  imports: [NzCollapseModule,BrowserAnimationsModule,NzToolTipModule],
  templateUrl: './workflow-history-dialog.component.html',
  styleUrls: ['./workflow-history-dialog.component.scss']
})
export class WorkflowHistoryDialogComponent {
  constructor(public modal: NzModalRef,){

  }
  expandIconPosition: 'right' | 'left' = 'right'; // or 'right'
  @ViewChild('extraTpl') extraTpl!: TemplateRef<any>;
  @Input() data: any
  panels = [
    {
      name: 'Step 1: Design',
      active: false,
      disabled: false,
      content: [
        { email: 'akal@clavis.com', icon: '', status: 'Rejected' },
        { email: 'ashish@clavis.com', icon: '', status: 'Approved' },
        { email: 'sharma@clavis.com', icon: '', status: 'No Action' }
      ],
      status: 'Completed'
    },
    {
      name: 'Step 2: Development and maybe a longer stage text',
      active: false,
      disabled: false,
      content: [
        { email: 'akal@clavis.com', icon: '', status: 'Rejected' },
        { email: 'ashish@clavis.com', icon: '', status: 'Approved' },
        { email: 'sharma@clavis.com', icon: '', status: 'No Action' }
      ],
      status: 'Completed'
    },
    {
      name: 'Step 3: This is the active ongoing step',
      active: true,
      disabled: false,
      content: [
        { email: 'akal@clavis.com', icon: 'M', status: 'Rejected' },
        { email: 'ashish@clavis.com', icon: '', status: 'Approved' },
        { email: 'sharma@clavis.com', icon: '', status: 'No Action' }
      ],
      status: 'Ongoing'
    },
    {
      name: 'Step 4: This step is yet to start',
      active: false,
      disabled: false,
      content: [
        { email: 'akal@clavis.com', icon: '', status: 'Rejected' },
        { email: 'ashish@clavis.com', icon: '', status: 'Approved' },
        { email: 'sharma@clavis.com', icon: '', status: 'No Action' }
      ],
      status: 'Pending'
    }
    // Add more panels as needed
  ];

  

  getStatusColor(status: string): string {
    switch (status) {
      case 'Completed':
        return 'rgb(0, 181, 26)';
      case 'Ongoing':
        return 'rgb(255, 165, 0)';
      case 'Pending':
        return 'rgb(75, 186, 227)';
      case 'Rejected':
        return 'rgb(255, 0, 0)';
      default:
        return 'rgb(128, 128, 128)';
    }
  }

  getItemStatusColor(status: string): string {
    switch (status) {
      case 'Rejected':
        return 'rgb(255, 0, 0)'; // Red
      case 'Approved':
        return 'rgb(0, 181, 26)'; // Green
      case 'No Action':
        return 'rgb(128, 128, 128)'; // Gray
      default:
        return 'rgb(128, 128, 128)'; // Default color
    }
  }
  // getStatus(status: string): string{
  //   switch (status) {
  //     case 'rejected':
  //       return 'Rejected'; // Red
  //     case 'approved':
  //       return 'Approved'; // Green
  //     case 'No Action':
  //       return 'No Action'; // Gray
  //     case 'pending':
  //       return 'Pending'; // Gray
  //     default:
  //       return status; // Default color
  //   }
  // }

  closebtn(){
    this.modal.destroy();
  }
}
