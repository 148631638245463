import { Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as UserActions from 'src/app/store/user/actions'
import * as Reducers from 'src/app/store/reducers'
import { AuthService as Auth } from 'src/app/services/common/auth.service'
import { GlobalService } from 'src/app/services/common/global.service'
import { Router } from '@angular/router'
import { AuthService } from 'src/app/services/common/auth.service'
import { NzModalService } from 'ng-zorro-antd/modal'
import { UserListComponent } from 'src/app/pages/user-list/user-list.component'
import { ThemeService } from 'src/app/services/theme.service'
import * as SettingsActions from 'src/app/store/settings/actions'
import { AuthZeroService } from 'src/app/services/auth-zero.service'
@Component({
  selector: 'qr-topbar-user-menu',
  templateUrl: './topbar-user-menu.component.html',
  styleUrls: ['./topbar-user-menu.component.scss'],
})
export class TopbarUserMenuComponent implements OnInit {
  name: string = ''
  role: string = ''
  email: string = ''
  phone: string = ''
  isAdmin = false
  profileFname: string = null
  profileImage: string = null
  isTeamUrl: boolean

  constructor(
    private auth0 : AuthZeroService,
    private modalService: NzModalService,
    private store: Store<any>,
    private authService: Auth,
    private _authService: AuthService,
    private globalService: GlobalService,
    private _router: Router,
    private themeService: ThemeService,
  ) {
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.name = state.name
      this.role = state.role
      this.email = state.email
    })
  }

  ngOnInit(): void {
    this.isAdmin = parseInt(localStorage.getItem('userId')) == 1 ? true : false
    this.globalService.isTeamUrl.subscribe(isTeamUrl => {
      this.isTeamUrl = isTeamUrl
    })
    this.globalService.userInfo.subscribe(userInfo => {
      if (userInfo) {
        this.profileFname = userInfo.displayName.charAt(0)
      } else {
        this.globalService.updateUserInfo.next(true)
      }
    })
    // this._authService.getProfile().subscribe(res => {
    //   this.profileFname = res[0].display_name.charAt(0)
    //   this.profileImage = ''
    // })
  }

  navigateToUserList() {
    const editEmailRef = this.modalService.create({
      nzTitle: '',
      nzContent: UserListComponent,
      nzFooter: null,
      nzWidth: 700,
      nzStyle: { top: '12px' },
    })
  }
  logout() {
    if (localStorage.getItem('auth0') && localStorage.getItem('auth0') != null) {
      localStorage.removeItem('auth0');
      localStorage.removeItem('qrtoken')
      localStorage.removeItem('userId')
      localStorage.removeItem('userEmail')
      localStorage.removeItem('userPassword')
      localStorage.removeItem('dropBoxAccessToken')
      localStorage.removeItem('isSocial')
      localStorage.removeItem('youToken');
      localStorage.removeItem('boxAccessToken')
      localStorage.removeItem('boxRefreshToken')
      this.auth0.logout();
    } else {
      this.authService.logout()
    }

    if (this.globalService.iFramed == true) {
      window.parent.postMessage({ iFramed: 'logout', userId: '' }, '*')
      window.parent.postMessage({ selectedFolder: { folderId: null } }, '*')
    }
  }

  setTheme(nextTheme) {
    // this._authService.setTheme({ theme: nextTheme }).subscribe(
    //   res => {},
    //   err => {
    //     console.log(err)
    //   },
    // )
    this.globalService.themeName.next(nextTheme)
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        theme: nextTheme,
      }),
    )
  }
}
